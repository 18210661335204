import {Log} from "../classes/log";

export class toArrayValueConverter {
    toView(value): any[] {
        let arr = [];
        Object.keys(value)
            .forEach(key => {
                let obj = {key, value: value[key]};
                arr.push(obj);
            });

        return arr;
    }
}
