import {autoinject} from "aurelia-framework";
import {HttpClient, HttpResponseMessage} from "aurelia-http-client";
import {RuntimeInfo} from "../classes/RuntimeInfo";
import {FhirService} from "./FhirService";
import {NitTools} from "../classes/NursitTools";
import * as environment from "../../../config/environment.json";
import {ConfigService} from "./ConfigService";

@autoinject
export class PumpService {
    constructor() {
    }

    public static async GetResources(requestUrl: string, hash: string): Promise<any[]> {
        const r = await this.GetResourcesAjax(requestUrl, hash);
        return Promise.resolve(r);
    }

    public static async GetResourcesAjax(requestUrl: string, hash: string): Promise<any[]> {
        return new Promise((resolve, reject) => {

            if (!/http.?:\/\//.test(requestUrl))
                requestUrl = `${NitTools.ExcludeTrailingSlash(RuntimeInfo.DataProxy.source)}/${NitTools.ExcludeLeadingSlash(requestUrl)}`;

            if (!RuntimeInfo.DataProxy.isPrincipa && !/_count=/.test(requestUrl)) {
                if (requestUrl.indexOf('?') === -1) {
                    requestUrl += '?';
                } else {
                    requestUrl += '&';
                }

                requestUrl += '_count=100';
            }

            //sessionStorage.setItem(environment.principaSessionName
            let params = NitTools.GetUrlParams();
            let auth = String(hash || (params.sessionId || params.sessionid) || PumpService.Hash || FhirService.EmbeddedUserHash);
            if (!RuntimeInfo.DataProxy.isPrincipa) auth = `Basic ${auth}`;

            $.ajax({
                method: 'GET',
                url: requestUrl,
                headers: {
                    Accept: 'application/fhir+json',
                    Prefer: 'return=representation',
                    Authorization: auth
                },
                dataType: "application/json+fhir",
                contentType: 'application/json+fhir',
                statusCode: {
                    0: (result) => {
                        let cors = `Access to XMLHttpRequest <br />at "${requestUrl}" <br />has been blocked by CORS policy!`;
                        let msg = "HttpResponse: " + result.statusText + "\n" + cors;
                        if (ConfigService.Debug) {
                            msg += result.statusText ? `<br /><div class="bg-warning text-warning"><span style="font-size: 0.7em">\nStatus: ${cors}</span></div>` : '';
                        }

                        return reject(msg);
                    },
                    200: async (strResult) => {
                        try {
                            let js = <any>JSON.parse(strResult.responseText);
                            if (js.resourceType === "Bundle") {
                                const bundle = <any>js;
                                if (!bundle.entry) return resolve([]);

                                let rTempArray = bundle.entry.map(o => o.resource);
                                if (bundle.link) {
                                    const next = bundle.link.find(o => String(o.relation).toUpperCase() === 'NEXT');
                                    if (next) {
                                        const r2 = await this.GetResourcesAjax(next.url, hash);
                                        rTempArray.push(...r2);
                                    }
                                }

                                return resolve(rTempArray);
                            } else {
                                return resolve([js]);
                            }
                        } catch (e) {
                            return reject(e);
                        }
                    },
                    401: (result) => {
                        return reject('401 - Login denied');
                    },
                    404: (result) => {
                        return reject('404 - Not found');
                    },
                    500: result => {
                        return reject('500 - Internal Server-error');
                    }
                }
            });
        })
    }

    public static async GetResourcesHttp(url: string, hash?: string) {
        let result: any[] = [];
        let request = this.GetRequest(url, hash);
        let response: HttpResponseMessage = await request.send();
        if (response.statusCode != 200) {
            throw `Error getting data from ${url}, StatusCode: ${response.statusCode} (${response.statusText})`;
        }

        let obj = JSON.parse(response.response);
        if (obj && obj.resourceType && obj.resourceType === 'Bundle') {
            let bundle = <any>obj;
            bundle.entry.forEach(be => result.push(be.resource));

            let nextLink = bundle.link.find(o => o.relation === "next");
            if (nextLink) {
                let arr: any = await this.GetResourcesHttp(nextLink.url, hash);
                arr.entry.forEach(entry => result.push(entry.resource));
            }

            return result;
        }

        return obj;
    }

    public static get Hash(): string {
        return sessionStorage.getItem(environment.principaSessionName);
    }

    public static async GetLocations(hash?: string): Promise<any[]> {
        const r = <any[]>await this.GetResources('Location', hash);
        return Promise.resolve(r);
    }

    public static async PumpUrl(url: string, encounterId: string, hash?: string): Promise<any[]> {
        url = url.replace(/%ENCOUNTERID%/gi, encounterId);
        let result: any[] = [];

        let request = this.GetRequest(url, hash);
        let response = await request.send();
        let js: any = JSON.parse(response.content);
        if (js.resourceType === "Bundle") {
            let bundle = <any>js;
            bundle.entry.forEach(entry => {
                result.push(entry.resource);
            })
        } else {
            result.push(js);
        }

        return result;
    }

    public static GetRequest(url: string, hash?: string) {
        let params = NitTools.GetUrlParams();
        let auth = String(hash || (params.sessionId || params.sessionid) || PumpService.Hash || FhirService.EmbeddedUserHash);
        if (!RuntimeInfo.DataProxy.isPrincipa) auth = `Basic ${auth}`;
        let request = new HttpClient().createRequest(url)
            .withBaseUrl(RuntimeInfo.DataProxy.source)
            .withHeader('Accept', 'application/fhir+json')
            .withHeader('Content-Type', 'application/fhir+json')
            .withHeader('Prefer', 'return=representation')
            // don't forget that principa does not know standard "Basic xxxx" header but uses just the hash
            .withHeader('Authorization', auth)
            .asGet();

        return request;
    }
}
