import { RuntimeInfo } from "resources/classes/RuntimeInfo";

export class DualNumberValueConverter {
    toView(value: number) {
        if (value < 10) return `0${value}`;
        else return `${value}`;
    }

    fromView(value) {
        return parseInt(value);
    }
}

export class EuroValueConverter {
    toView(value: number) {        
        if (!value) value = 0;

        return new Intl.NumberFormat(RuntimeInfo.Language, { style: 'currency', currency: 'EUR' }).format(value);
    }

    fromView(value: string) {
        let val = parseFloat(value);
        if (!val) val = 0;

        return val;
    }
}

export class HourValueConverter {
    toView(value: number) {
        if (!value) value = 0;
        if (value > 23) value = 23;
        if (value < 10) return `0${value}`;
        else return `${value}`;
    }

    fromView(value: string) {
        if (value.length > 2) value = value.substr(0, 2);
        let val = parseInt(value);
        if (val < 0) val = 0;
        if (val > 23) val = 23;

        return val;
    }
}

export class MinuteValueConverter {
    toView(value: number) {
        if (!value) value = 0;
        if (value > 59) value = 59;
        if (value < 10) return `0${value}`;
        else return `${value}`;
    }

    fromView(value: string) {
        if (value.length > 2) value = value.substr(0, 2);
        let val = parseInt(value);
        if (val < 0) val = 0;
        if (val > 59) val = 59;

        return val;
    }
}
