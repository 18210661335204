import {ConfigService} from "../services/ConfigService";

const moment = require('moment');
import {I18N} from "aurelia-i18n";
import {inject} from "aurelia-framework";
import {RuntimeInfo} from "../classes/RuntimeInfo";

export class DateValueConverter {
    toView(value) {
        if (!value) return "";
        let ret = moment(value).format(RuntimeInfo.DateFormat);

        return ret;
    }

    fromView(value) {
        return moment(value).toDate(); // , RuntimeInfo.DateFormat);
    }
}

@inject(I18N)
export class ShortDateValueConverter {
    i18n;

    constructor(i18n: I18N) {
        this.i18n = i18n;
    }

    toView(value) {
        if (!value) return "";
        return moment(value).format(this.i18n.tr("short_date_format"));
    }

    fromView(value) {
        return moment(value, this.i18n.tr("short_date_format"));
    }
}

export class TimeValueConverter {
    toView(value) {
        if (!value) return "";
        return moment(value).format(RuntimeInfo.TimeFormat);
    }

    fromView(value) {
        return moment(value, RuntimeInfo.TimeFormat);
    }
}

export class DateTimeValueConverter {
    toView(value) {
        if (!value) return "";
        return moment(value).format(RuntimeInfo.DateTimeFormat);
    }

    fromView(value) {
        return moment(value, RuntimeInfo.DateTimeFormat);
    }
}
