import { NitTools } from "../classes/NursitTools";
import { PatientItem } from "resources/classes/Patient/PatientItem";

export class PatientChangeNotifier {

    get subscribers(): any[] {
        return PatientChangeNotifier._subscribers;
    }

    static _subscribers: any[] = [];

    public static Notify(patient: PatientItem, resource?: any) {
        this._subscribers.forEach(sub => {
            if (sub.event) {
                // sub.event(patient, this, sub.id);
                sub.event(patient, resource);
            }
        });
    }

    notify(patient: PatientItem, resource?: any) {
        PatientChangeNotifier.Notify(patient, resource);
    }

    subscribe(event: Function): string {
        let id = NitTools.Uid();
        this.subscribers.push({ id: id, event: event });

        return id;
    }

    unsubscribe(id: string) {
        let item = this.subscribers.find(o => o.id === id);
        if (!item) return;
        let idx = this.subscribers.indexOf(item);

        if (idx > -1) {
            this.subscribers.splice(idx, 1);
        }
    }
}
