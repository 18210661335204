import { autoinject, } from "aurelia-framework";
import { FEATURES as _FEATURES } from "resources/classes/PermissionFeatures";
import { DialogMessages } from "./DialogMessages";
import { FhirService } from "./FhirService";
import { I18N } from "aurelia-i18n";
import { ConfigService } from "./ConfigService";

const FEATURE_SYSTEM = 'http://hospital.com/fhir/rbac-features'

@autoinject
export class PermissionService {
  static readonly FEATURES = _FEATURES;
  static readonly STORAGE_KEY = 'rbac-active-role';
  static readonly CONFIG_RBAC_KEY = 'rbacEnabled';

  private fhirService: FhirService;
  private dialogMessages: DialogMessages;
  private i18n: I18N;
  private features: { [key: string]: string[] | null } = {}
  private userRoles: fhir4.Group[] = []
  private _activeUserRole: fhir4.Group | null = null

  constructor(dialogMessages: DialogMessages, i18n: I18N) {
    this.fhirService = new FhirService();
    this.dialogMessages = dialogMessages;
    this.i18n = i18n;
  }

  get numAvailableRoles() {
    return this.userRoles.length;
  }

  get activeUserRole() {
    return this._activeUserRole ? {
      id: this._activeUserRole.id,
      name: this._activeUserRole.name,
    } : null;
  }

  get isRolesEnabled() {
    return ConfigService.cfg[PermissionService.CONFIG_RBAC_KEY];
  }

  getUserRoles() {
    return this.userRoles.map(role => ({
      id: role.id,
      name: role.name,
    }));
  }

  checkActiveUserRole() {
    if (!this.isRolesEnabled) {
      return true;
    }

    if (sessionStorage.getItem(PermissionService.STORAGE_KEY)) {
      return this.assignUserRole(sessionStorage.getItem(PermissionService.STORAGE_KEY));
    } else if (this.userRoles.length === 1) {
      return this.assignUserRole(this.userRoles[0].id);
    }

    return false
  }

  assignUserRole(roleId: string) {
    this._activeUserRole = this.userRoles.find(role => role.id === roleId);

    if (!this._activeUserRole) {
      return false
    }

    sessionStorage.setItem(PermissionService.STORAGE_KEY, this._activeUserRole.id);
    
    this.features = {};
    this._activeUserRole.characteristic?.forEach(characteristic => {
      const characteristicCodeableConcept = characteristic.code?.coding?.[0]

      if (characteristicCodeableConcept?.system === FEATURE_SYSTEM) {
        const parameters = characteristic.valueCodeableConcept?.coding?.map(coding => coding.code) || null

        this.features[characteristicCodeableConcept.code] = parameters
      }
    })

    // this.features[FEATURES.BODY_ADD_WOUNDS] = ['wunde', 'dekubitus'];
    // this.features[FEATURES.BODY_EDIT_WOUNDS] = ['wunde', 'dekubitus'];

    return true
  }

  async fetch(practitioner) {
    this.userRoles = await this.fhirService.fetch(`Group?member=Practitioner/${practitioner.id}`)
  }

  can(permission: string, parameter?: string) {
    if (!this.isRolesEnabled) {
      return true;
    }

    if (!PermissionService.FEATURES.hasOwnProperty(permission)) {
      throw new Error(`Permission '${permission}' does not exist`)
    }

    if (!this.features.hasOwnProperty(permission)) {
      return false
    }

    // check for required parameter only if the feature has a parameters array (not null), otherwise implicitly allow
    if (parameter && this.features[permission] && !this.features[permission].includes(parameter)) {
      return false
    }

    return true
  }

  alert() {
    return this.dialogMessages.prompt(this.i18n.tr('permissions_deny_title'), this.i18n.tr("error"), true);
  }

  // shorthand can + alert
  canAlert(permission: string, parameter?: string) {
    if (!this.can(permission, parameter)) {
      this.alert()

      return false
    }

    return true
  }
}
