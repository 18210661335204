import { LocationService } from "./LocationService";
import { IQuestionnaireList, QuestionnaireService } from "./QuestionnaireService";
import { NitTools } from "../classes/NursitTools";
import { PatientItem } from "resources/classes/Patient/PatientItem";
import { CIRiskAssessment, Patient, QuestionnaireResponse, Tools } from "resources/classes/FhirModules/Fhir";
import { autoinject, bindable, observable } from "aurelia-framework";
import { fhirEnums } from "../classes/fhir-enums";
import { FhirService } from "./FhirService";
import { UserService } from "./UserService";
import { ConfigService } from "./ConfigService";
import { RuntimeInfo } from "../classes/RuntimeInfo";
import { IQuestionnaireListItem } from "../classes/IQuestionnaireListItem";
import * as environment from "../../../config/environment.json";
import { PumpService } from "./PumpService";
import { I18N } from "aurelia-i18n";

const Fhir = require("resources/classes/FhirModules/Fhir");
const moment = require("moment");

@autoinject
export class PatientService {
    get patients(): PatientItem[] {
        return PatientItem.Patients;
    }

    public static get LatestPatient(): PatientItem {
        return PatientItem.SelectedPatient;
    }

    public get latestPatient(): PatientItem {
        return PatientItem.SelectedPatient;
    }

    private readonly fhirService: FhirService;

    constructor(protected i18n: I18N) {
        this.fhirService = new FhirService();
    }

    public static AddRiskAssessment(patient: PatientItem, riskAssessment: any) {
        if (!patient.riskAssessments) {
            patient.riskAssessments = [];
        }

        const idx = patient.riskAssessments.findIndex(o => o.id === riskAssessment.id);

        if (idx === -1) {
            patient.riskAssessments.push(riskAssessment);

            // resort
            patient.riskAssessments.sort((a, b) => {
                try {
                    const startA = a.occurrencePeriod && a.occurrencePeriod.start ? a.occurrencePeriod.start : a.occurrenceDateTime;
                    const startB = b.occurrencePeriod && b.occurrencePeriod.start ? b.occurrencePeriod.start : b.occurrenceDateTime;
                    const dateA = new Date(startA);
                    const dateB = new Date(startB);

                    return dateB.valueOf() - dateA.valueOf();
                } catch (e) {
                    console.warn(e.message || e);

                    return 0;
                }
            })
        } else {
            patient.riskAssessments[idx] = riskAssessment;
        }
    }

    public addQuestionnaireResponse(patient: PatientItem, newResponse: any, overwrite: boolean = false) {
        PatientService.AddQuestionnaireResponse(patient, newResponse, overwrite);
    }

    public static AddQuestionnaireResponse(patient: PatientItem, newResponse : any, overwrite: boolean = false): boolean {
        if (patient && newResponse) {
            if (!patient.questionnaireResponses) patient.questionnaireResponses = [];
            if (newResponse.subject?.reference.indexOf('/'+patient?.id) === -1) {
                console.warn(`trying to add QuestionnaireResponse for patientId "${newResponse.subject?.reference}" to patient ${patient.id}`);
                return false;
            }

            let existing = patient.questionnaireResponses.find(o => o.id === newResponse.id);
            // only push if not existent
            if (!existing) {
                patient.questionnaireResponses.push(newResponse);
                return true;
            } else {
                if (overwrite && existing) {
                    let idx = patient.questionnaireResponses.indexOf(existing);
                    if (idx > -1) {
                        patient.questionnaireResponses[idx] = newResponse;
                        return true;
                    }
                }
            }
        }

        return true;
    }

    public getQuestionnaireListForCombo(questionnaireId: string, patient: PatientItem, appendStatus?: boolean): IQuestionnaireListItem[] {
        // TODO: move procedure into here
        return Fhir.Tools.GetQuestionnaireListForCombo(questionnaireId, patient, appendStatus);
    }

    public setSelectedAdditionalInfo(patient: PatientItem, newAdditionalInfo: any) {
        PatientService.SetSelectedAdditionalInfo(patient, newAdditionalInfo);
    }

    public static SetSelectedAdditionalInfo(patient: PatientItem, newAdditionalInfo: any) {
        PatientService.AddQuestionnaireResponse(patient, newAdditionalInfo);
        if (!patient.questionnaireResponses) patient.questionnaireResponses = [];
        patient.selectedAdditionalInfo = newAdditionalInfo;
    }

    private createMissingDocument(patient: PatientItem, questionnaireId: string, basedOn?: string, status?: fhirEnums.QuestionnaireResponseStatus): Promise<any> {
        return new Promise<any>((resolve, reject) => {
            if (!patient || !patient.encounterId) return reject('No patient/encounter given');
            let skel = Fhir.Tools.SubstituteDefaultQRSkeleton(patient, questionnaireId, status);
            this.fhirService.create(skel)
                .then((result: any) => {
                    PatientService.AddQuestionnaireResponse(patient, result);
                    resolve(result);
                    return;
                })
                .catch(error => {
                    console.warn(error);
                    reject("Could not create document");
                    return;
                })
        });
    }

    public ensureQuestionnaireExistance(patient: PatientItem, questionnaireId: string | string[], basedOn?: string, status?: fhirEnums.QuestionnaireResponseStatus): Promise<any[]> {
        return new Promise<any[]>((resolve, reject) => {
            if (NitTools.IsArray(questionnaireId)) {

                // get the missing documents only
                let missingArr: string[] = [];
                (<string[]>questionnaireId).forEach(aId => {
                    let qr = QuestionnaireService.GetLatestResponseOfType(patient, aId, []);
                    if (!qr) {
                        missingArr.push(aId);
                    }
                });

                // create the missing documents
                let cnt = missingArr.length;
                let resultArray = [];
                missingArr.forEach(qId => {
                    this.createMissingDocument(patient, qId, basedOn, status)
                        .then((result: any) => {
                            console.debug("created missing document");
                            resultArray.push(result);
                            PatientService.AddQuestionnaireResponse(patient, result);
                            cnt--;

                            if (cnt <= 0) {
                                resolve(resultArray);
                                return;
                            }
                        })
                        .catch(error => {
                            console.warn(error);
                            reject("Could not create multiple documents");
                            return;
                        })
                });
            } else {
                let qr = QuestionnaireService.GetLatestResponseOfType(patient, <string>questionnaireId, []);
                if (!qr) {
                    this.createMissingDocument(patient, <string>questionnaireId, basedOn, status)
                        .then((result: any) => {
                            PatientService.AddQuestionnaireResponse(patient, result);
                            resolve([result]);
                        })
                        .catch(error => {
                            console.warn(error);
                            reject("Could not create single document");
                        })
                } else {
                    resolve([]);
                }
            }
        });
    }

    public fetch(encounterId: string): Promise<PatientItem> {
        return PatientItem.Load(encounterId);
    }

    async checkForRiskAssessment(p: PatientItem, spi) {
        if (!p || !p.encounter) return;
        if (!p.currentRisks) {
            let qList = await QuestionnaireService.GetQuestionnaireIds();
            let latestAnamnesis = QuestionnaireService.GetLatestResponseOfType(p, qList.QAnamnesisId, [fhirEnums.QuestionnaireResponseStatus.amended, fhirEnums.QuestionnaireResponseStatus.completed]);
            let latestAssessment = QuestionnaireService.GetLatestResponseOfType(p, qList.QAssessmentId, [fhirEnums.QuestionnaireResponseStatus.amended, fhirEnums.QuestionnaireResponseStatus.completed]);
            if (latestAnamnesis && latestAssessment) {
                let risks = CIRiskAssessment.CreateRiskAssessment(p.encounter, p, UserService.Practitioner, latestAssessment, latestAnamnesis);
                if (!risks.prediction) risks.prediction = [];
                let pred: any = {
                    id: "http://nursit-institute.com/created",
                    outcome: {
                        text: new Date().toJSON()
                    }
                };

                risks.prediction.push(pred);

                if (spi && spi > 0) {
                    pred = {
                        id: "http://nursit-institute.com/risk_spi_sum",
                        outcome: {
                            coding: [
                                {
                                    code: String(spi),
                                    system: 'http://nursit-institute.com/risk_spi_sum'
                                }
                            ],
                            text: String(spi)
                        }
                    };
                }

                p.currentRisks = <any>await this.fhirService.create(risks);
            }
        }
    }

    isLoading: boolean;

    waitForLoading(): Promise<any> {
        return new Promise<any>(async (resolve) => {
            if (!this.isLoading) {
                resolve(true);
            } else {
                window.setTimeout(async () => {
                    await this.waitForLoading();
                    resolve(false);
                }, 100);
            }
        })
    }

    public updatePatientDischargePct(patient: PatientItem) {
        return PatientService.UpdatePatientDischargePct(patient, this.i18n);
    }

    public static UpdatePatientDischargePct(patient: PatientItem, i18n: I18N) {
        PatientItem.UpdatePatientDischargePct(patient, i18n);
    }

    private static arrToChunks(array, chunkSize): any[] {
        let R = [];
        for (let i = 0; i < array.length; i += chunkSize)
            R.push(array.slice(i, i + chunkSize));

        return R;
    }

    public async parseFlagSignalreiterFromFlags(item: PatientListItem | PatientItem) {

    }

    private async loadPatientSeperately(encounter: any): Promise<any> {
        let result: any = undefined;
        if (encounter.subject && encounter.subject.reference) {
            let patientId: string = encounter.subject.reference;
            if (patientId) {
                if (patientId.indexOf('/') > -1) {
                    patientId = patientId.split('/')[1];
                }

                const pumpHash = sessionStorage.getItem(RuntimeInfo.DataProxy.isPrincipa ? environment.principaSessionName : environment.sessionName);
                result = <any>await PumpService.GetResources(`Patient/${patientId}`, pumpHash)[0];
            }
        }

        return Promise.resolve(result);
    }
}

@observable({ name: 'mark_1', changeHandler: 'mark1Changed' })
@observable({ name: 'mark_1_red', changeHandler: 'mark1Changed' })
@observable({ name: 'mark_2', changeHandler: 'mark2Changed' })
@observable({ name: 'mark_3', changeHandler: 'mark3Changed' })
@observable({ name: 'mark_4', changeHandler: 'mark4Changed' })
@observable({ name: 'mark_5', changeHandler: 'mark5Changed' })
@observable({ name: 'mark_6', changeHandler: 'mark6Changed' })
@observable({ name: 'mark_7', changeHandler: 'mark7Changed' })
@observable({ name: 'mark_8', changeHandler: 'mark8Changed' })
@observable({ name: 'mark_9', changeHandler: 'mark9Changed' })
@observable({ name: 'mark_10', changeHandler: 'mark10Changed' })
@observable({ name: 'mark_10_yellow', changeHandler: 'mark10Changed' })
@observable({ name: 'mark_10_red', changeHandler: 'mark10Changed' })
export class PatientListItem {
    room: string;
    roomId: string;
    bed: string;
    bedId: string;
    ward: string;
    wardId: string;
    search: string;
    id: string;
    gender: ('male'|'female'|'other'|'unknown') | undefined = 'unknown';

    setMarkClass(markId) {
        this[markId + "_class"] = this[markId] ? 'active' : '';
    }

    mark_1: boolean;
    mark_1_red: boolean;
    mark_2: boolean;
    mark_3: boolean;
    mark_4: boolean;
    mark_5: boolean;
    mark_6: boolean;
    mark_7: boolean;
    mark_8: boolean;
    mark_9: boolean;
    mark_10: boolean;
    mark_10_red: boolean;
    mark_10_yellow: boolean;
    display: string;

    mark_1_class: string;
    mark_2_class: string;
    mark_3_class: string;
    mark_4_class: string;
    mark_5_class: string;
    mark_6_class: string;
    mark_7_class: string;
    mark_8_class: string;
    mark_9_class: string;
    mark_10_class: string;

    latestAssessment: any;
    careLevel: number;
    careLevelString: string;
    careLevelColor: string;

    hasDischarge: boolean = false;
    dischargeColor: string;
    dischargeTitle: string;
    dischargePercentString: string;
    flags: any;
    additionalInfo: any;
    marks = ['mark_1', 'mark_2', 'mark_3', 'mark_4', 'mark_5', 'mark_6', 'mark_7', 'mark_8', 'mark_9', 'mark_10'];
    riskAssessment: any;

    encounter: any;
    _encounterId: string = undefined;
    _isOffline?: boolean = undefined;

    checkOffline(): any {
        const info = Fhir.Tools.GetOfflineInfo(this.encounter);
        this._isOffline = info.offline;
        return info;
    }

    get isOffline(): boolean {
        if (typeof this._isOffline === "undefined") {
            const info = Fhir.Tools.GetOfflineInfo(this.encounter);
            this._isOffline = info.offline;
        }

        return this._isOffline;
    }

    get encounterId(): string {
        if (this._encounterId) return this._encounterId;
        if (this.encounter) return this.encounter.id;
        else {
            console.warn("No Encounter Id found", this);
        }
    }

    set encounterId(value: string) {
        this._encounterId = value;
    }

    update(patient: PatientItem) {
        this.mark_1_red = patient.mark_1_red;
        this.mark_1 = patient.mark_1;
        this.mark_2 = patient.mark_2;
        this.mark_3 = patient.mark_3;
        this.mark_4 = patient.mark_4;
        this.mark_5 = patient.mark_5;
        this.mark_6 = patient.mark_6;
        this.mark_7 = patient.mark_7;
        this.mark_8 = patient.mark_8;
        this.mark_9 = patient.mark_9;
        this.mark_10 = patient.mark_10;
        this.mark_10_red = patient.mark_10_red;
        this.mark_10_yellow = patient.mark_10_yellow;

        this.careLevel = patient.careLevel;
        this.careLevelString = patient.careLevelString;
        this.careLevelColor = patient.careLevelColor;
    }

    findFlag(id: string, flag: any) {
        let f = flag.code.coding.find(o => o.system.endsWith('/' + id));
        if (f) {
            this[id] = NitTools.ParseBool(f.code);
        }
    }

    async ensureFlags(debug?: boolean): Promise<any> {
        if (typeof this.flags === "undefined" && typeof this.additionalInfo !== "undefined") {
            let system = `${NitTools.ExcludeTrailingSlash(environment.nursItStructureDefinition)}/marks`;

            let flag: any = {
                encounter: { reference: this.additionalInfo.context.reference },
                status: 'active',
                id: NitTools.Uid(),
                resourceType: "Flag",
                subject: { reference: `Patient/${this.id}` },
                identifier: [{ system: system, value: "marks" }],
                code: { coding: [] }
            };

            this.marks.forEach(m => {
                let mItem = QuestionnaireResponse.GetResponseItemByLinkId(this.additionalInfo, m);
                if (mItem) {
                    let itemValue = QuestionnaireResponse.GetResponseItemValue(mItem);
                    if (typeof itemValue === "undefined") itemValue = "false";
                    let flagCodeItem = {
                        system: `${system}/${m}`,
                        code: itemValue
                    };

                    flag.code.coding.push(flagCodeItem);
                } else {
                    let flagCodeItem = {
                        system: `${system}/${m}`,
                        code: 'false'
                    };

                    flag.code.coding.push(flagCodeItem);
                }
            });

            this.createCareLevelItem(system, 'CareLevel', flag);
            this.createCareLevelItem(system, 'CareLevelColor', flag);
            this.createCareLevelItem(system, 'CareLevelString', flag);
            this.flags = await Fhir.Rest.Create(flag);

            if (ConfigService.Debug) console.debug("Ensuring Flags - created new Flag for: " + this.display, this.flags);
        }

        return this.flags;
    }

    private createCareLevelItem(system: string, linkId: string, toFlag: any) {
        let mCL = QuestionnaireResponse.GetResponseItemByLinkId(this.additionalInfo, linkId, false);
        if (mCL) {
            let flagCodeItem = {
                system: `${system}/${mCL.linkId}`,
                code: String(QuestionnaireResponse.GetResponseItemValue(mCL))
            };

            toFlag.code.coding.push(flagCodeItem);
        }
    }

    // constructor(flags: any, patient: any, responses: any[], qList: IQuestionnaireList, riskAssessment: any) {
    constructor(flags: any, patient: any, qList: IQuestionnaireList, riskAssessment: any) {
        this.flags = flags;
        this.careLevel = -1;
        this.careLevelString = "-";
        this.careLevelColor = "white";

        if (patient) {
            this.id = patient.id;
            this.gender = patient.gender;
        }

        if (patient && patient.name && patient.name.length > 0) {
            let name = patient.name.find(o => o.use === 'official');
            if (!name) name = patient.name[0];
            this.display = name.family + ", " + name.given?.join(' ');
        }

        if (riskAssessment && riskAssessment.prediction) {
            this.riskAssessment = riskAssessment;
            let prediction = riskAssessment.prediction.find(o => typeof o !== "undefined" && o.outcome && o.outcome.coding && o.outcome.coding[0] && o.outcome.coding[0].system.endsWith('risk_spi_sum'));
            if (prediction && prediction.outcome && prediction.outcome.coding) {
                let coding = prediction.outcome.coding[0];
                if (coding) {
                    this.careLevel = Fhir.Tools.SpiToCareLevel(parseInt(coding.code));
                    this.careLevelString = coding.display;
                    this.careLevelColor = Fhir.Tools.CareLevelToColor(this.careLevel);
                }
            }
        }

        if (flags) {
            this.marks.forEach(m => this.findFlag(m, flags));
            if (flags.code && flags.code.coding) {
                if (ConfigService.UseAssessmentForSignal10) {
                    const sAssessment = ConfigService.GetFormSettings('assessment');
                    const assessmentRedAfter = sAssessment.expiration.durations.redAfter;
                    const assessmentYellowAfter = sAssessment.expiration.durations.yellowAfter;

                    let flagAssessment = flags.code.coding.find(o => o && o.system && o.system.toUpperCase().endsWith('latestCareITAssessmentDate'.toUpperCase()));
                    if (flagAssessment && flagAssessment.code) {
                        let assessmentAge = moment(new Date()).diff(new Date(flagAssessment.code), 'h');
                        if (assessmentAge > assessmentRedAfter) this.mark_10_red = true;
                        else if (assessmentAge > assessmentYellowAfter) this.mark_10_yellow = true;
                        else this.mark_10 = true;
                    } else {
                        this.mark_10_red = true;
                    }
                }

                if (ConfigService.UseIsolationForFlag1) {
                    if (this.mark_1) {
                        let flagIsolation = flags.code.coding.find(o => o && o.system && o.system.toUpperCase().endsWith('latestCareITAdmin_ISODate'.toUpperCase()));
                        if (flagIsolation && flagIsolation.code) {
                            let dAuthored = new Date(flagIsolation.code);
                            let now = new Date();
                            let ageHours = moment(now).diff(dAuthored, "h");
                            //*********************************************************************************
                            //#region get the reset Time from config|default
                            let h = 0;
                            let m = 0;
                            let config = ConfigService.GetFormSettings("isolation");
                            let resetConfigTime = (config && config.settings && config.settings["resetTime"]) ? config.settings["resetTime"] : "00:00";
                            if (resetConfigTime.indexOf(":") > -1) {
                                h = resetConfigTime.split(":")[0];
                                m = resetConfigTime.split(":")[1];
                            }
                            //#endregion

                            // the expiration date of the iso-document.authored is the next day @ the configured time
                            // so when the document is created @ 01.01.2020 the expiration should be the 02.01.2020 + resetTime
                            let expirationDate = new Date(flagIsolation.code);
                            expirationDate.setMilliseconds(0);
                            expirationDate.setSeconds(0);
                            expirationDate.setMinutes(m);
                            expirationDate.setHours(h);
                            expirationDate.setDate(expirationDate.getDate() + 1);

                            this.mark_1_red = moment(now).isAfter(expirationDate);
                        } else {
                            // no iso, but iso set
                            this.mark_1_red = true;
                        }
                    } else {
                        this.mark_1_red = false;
                    }
                }
            }
        }
        //#endregion
    }
}

